export default {
  data: () => ({
    myReportsStatus: {
      'REQUESTED': 'Solicitado',
      'PROCESSING': 'Em Processamento',
      'FAIL': 'Erro na Emissão',
      'SUCCESS': 'Emitido com Sucesso'
    },
    reportLayoutTypes: {
      'REPORT_MOVEMENT_RECORD_DEFAULT': 'Relatório Movimentação Cadastral Padrão',
      'REPORT_MOVEMENT_RECORD_ANALYTICAL': 'Relatório Movimentação Cadastral Analítico',
      'REPORT_MOVEMENT_RECORD_OPERATIONAL' : 'Relatório Movimentação Cadastral Operacional',
      'REPORT_FILE_DATA_ERRORS': 'Relatório de Erros',
      'REPORT_FILE_DATA_CORRECTION_FILE': 'Arquivo de Correção',
    }
  }),
  methods: {
    // returns the report status label
    getReportsStatus(status) {
      return this.myReportsStatus[status]
    }
  },
  computed: {
    // returns the list of statuses used in the page filter
    myReportsStatusFilter() {
      return Object.entries(this.myReportsStatus).map(
        ([id, name]) => ({ id, name }));
    },
    getReportLayoutTypesFilter() {
      return Object.entries(this.reportLayoutTypes).map(
        ([id, name]) => ({ id, name }));
    },
  }
}
